<div class="dialog-header">
    <h2 mat-dialog-title>
        {{
            (data.assignedOfficer
                ? "uavIdAdminLibReportMgmt.officerAssignDialog.editTitle"
                : "uavIdAdminLibReportMgmt.officerAssignDialog.addTitle"
            ) | transloco
        }}
    </h2>
    <button type="button" class="button-icon" (click)="closeDialog()">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div *ngrxLet="officers$ as officers" mat-dialog-content>
    <ng-container *ngIf="officers.length; else emptyListTemplate">
        <p class="confirmation-text">{{ "uavIdAdminLibReportMgmt.officerAssignDialog.confirmationText" | transloco }}</p>

        <dtm-ui-select-field [formControl]="assignedOfficerControl" [compareWith]="compareOfficers" [isClearable]="false">
            <label>{{ "uavIdAdminLibReportMgmt.officerAssignDialog.officerLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let officer of officers" [value]="officer">
                {{ officer | invoke : displayOfficerFullName }}
            </dtm-ui-select-option>
            <div class="field-error" *dtmUiFieldHasError="assignedOfficerControl; name: 'required'">
                {{ "uavIdAdminLibReportMgmt.officerAssignDialog.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-select-field>
    </ng-container>

    <ng-template #emptyListTemplate>
        <dtm-ui-info-message>
            {{ "uavIdAdminLibReportMgmt.officerAssignDialog.emptyListMessage" | transloco }}
        </dtm-ui-info-message>
    </ng-template>
</div>

<div mat-dialog-actions>
    <button type="button" class="button-secondary" (click)="closeDialog()">
        {{ "uavIdAdminLibReportMgmt.officerAssignDialog.cancelButtonLabel" | transloco }}
    </button>
    <button
        type="submit"
        class="button-primary"
        [disabled]="!(hasAssignedOfficerChanged$ | ngrxPush) || assignedOfficerControl.invalid"
        (click)="closeDialog(true)"
    >
        <span>
            {{
                (data.assignedOfficer
                    ? "uavIdAdminLibReportMgmt.officerAssignDialog.editConfirmButtonLabel"
                    : "uavIdAdminLibReportMgmt.officerAssignDialog.addConfirmButtonLabel"
                ) | transloco
            }}</span
        >
    </button>
</div>
