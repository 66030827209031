import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { UavIdentificationAdminEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<UavIdentificationAdminEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, uavAppUrl, sharedMapEndpoints }) => {
        const apiUrl = `https://${apiDomain}/api`;

        return {
            production: false,
            name: "dev",
            keycloakConfig,
            sharedMapEndpoints,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 6,
                    userPosition: 15,
                },
            },
            reportManagementEndpoints: {
                getReports: `${apiUrl}/uav-identification/reports`,
                getReportSummary: `${apiUrl}/uav-identification/reports/{{reportId}}`,
                getActiveOfficers: `${apiUrl}/uav-identification/users/officers/active`,
                getOfficers: `${apiUrl}/uav-identification/users/officers`,
                updateAssignedOfficer: `${apiUrl}/uav-identification/reports/{{id}}/intervening-officer`,
                updateNote: `${apiUrl}/uav-identification/reports/{{id}}/intervention-note`,
                updateStatus: `${apiUrl}/uav-identification/reports/{{id}}/status`,
                updateOfficerUnit: `${apiUrl}/uav-identification/reports/{{id}}/officer-unit`,
                getReportGeoZoneDetails: `${apiUrl}/uav-identification/geo-zones/{{zoneId}}/information`,
            },
            officerEndpoints: {
                getOfficerData: `${apiUrl}/uav-identification/users/{{officerId}}`,
                activateOfficer: `${apiUrl}/uav-identification/users/officers/{{officerId}}/activation`,
                deactivateOfficer: `${apiUrl}/uav-identification/users/officers/{{officerId}}/deactivation`,
                demoteOfficer: `${apiUrl}/uav-identification/users/officers/{{officerId}}/demotion`,
                getOfficers: `${apiUrl}/uav-identification/users/officers`,
                getAllOfficerUnits: `${apiUrl}/uav-identification/officer-units`,
                getUniformedServiceOfficerUnits: `${apiUrl}/uav-identification/uniformed-services/{{uniformedServiceId}}/officer-units`,
                promoteOfficer: `${apiUrl}/uav-identification/users/officers/{{officerId}}/promotion`,
                rejectOfficer: `${apiUrl}/uav-identification/users/officers/{{officerId}}/rejection`,
                updateOfficerUnit: `${apiUrl}/uav-identification/users/officers/{{officerId}}/officer-unit`,
            },
            uavAppUrls: {
                operatorProfile: `${uavAppUrl}/(public:operator/{{operatorId}})`,
                pilotProfile: `${uavAppUrl}/(public:pilot/{{pilotId}})`,
            },
        };
    }
);
