<ng-container *ngrxLet="displayedTableColumns$ as displayedTableColumns">
    <ng-container *ngrxLet="officersPage$ as page">
        <ng-container *ngrxLet="hasExtendedAccess$ as hasExtendedAccess">
            <ng-container *ngrxLet="hasGetListError$ as hasGetListError">
                <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
                    <dtm-ui-responsive-table *ngrxLet="officers$ as officers" [data]="officers">
                        <table
                            desktopTable
                            mat-table
                            matSort
                            class="dtm-table"
                            [dataSource]="officers"
                            (matSortChange)="sortChange.emit($event)"
                        >
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "uavIdAdminLibOfficer.officerList.statusLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <uav-id-shared-lib-status-badge-menu
                                        [configuration]="OFFICER_BADGE_CONFIGURATION"
                                        [status]="element.status"
                                        [availableStatuses]="element.status | invoke : getAvailableStatuses"
                                        (statusChange)="openStatusChangeDialog($event, element)"
                                    >
                                    </uav-id-shared-lib-status-badge-menu>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="firstName">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "uavIdAdminLibOfficer.officerList.firstNameLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
                            </ng-container>

                            <ng-container matColumnDef="lastName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">
                                    {{ "uavIdAdminLibOfficer.officerList.lastNameLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
                            </ng-container>

                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "uavIdAdminLibOfficer.officerList.emailLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.email }}
                                    <dtm-ui-popover
                                        *ngIf="element.type === UserType.DutyOfficer"
                                        class="duty-officer-icon"
                                        popoverIcon="shield-check"
                                        [popoverText]="'uavIdAdminLibOfficer.officerList.dutyOfficerLabel' | transloco"
                                    ></dtm-ui-popover>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="phoneNumber">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "uavIdAdminLibOfficer.officerList.phoneNumberLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.phoneNumber | formatPhoneNumber }}</td>
                            </ng-container>

                            <ng-container matColumnDef="officerUnit">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="officerUnit">
                                    {{ "uavIdAdminLibOfficer.officerList.officerUnitLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">{{ element.officerUnit.name }}</td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{ "uavIdAdminLibOfficer.officerList.actionsLabel" | transloco }}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button
                                        *ngIf="element.status === OfficerStatus.Active"
                                        class="button-icon"
                                        type="button"
                                        [matMenuTriggerFor]="rowActionsMenu"
                                        [matMenuTriggerData]="{ $implicit: element }"
                                    >
                                        <dtm-ui-icon name="more"></dtm-ui-icon>
                                    </button>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="noResults">
                                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                                    <ng-container
                                        *ngIf="hasGetListError; else emptyListTemplate"
                                        [ngTemplateOutlet]="errorTemplate"
                                    ></ng-container>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pagination">
                                <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                                    <ng-container *ngTemplateOutlet="paginationTemplate"></ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedTableColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedTableColumns"></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['noResults']"
                                class="no-results-row"
                                [class.hide-footer]="officers.length || isProcessing"
                            ></tr>
                            <tr
                                mat-footer-row
                                *matFooterRowDef="['pagination']"
                                class="pagination-row"
                                [class.hide-footer]="!officers.length"
                            ></tr>
                        </table>

                        <div *dtmUiMobileTable="let row">
                            <div class="mobile-table-header">
                                <h2>{{ row.firstName }} {{ row.lastName }}</h2>
                                <uav-id-shared-lib-status-badge-menu
                                    [configuration]="OFFICER_BADGE_CONFIGURATION"
                                    [status]="row.status"
                                    [availableStatuses]="row.status | invoke : getAvailableStatuses"
                                    (statusChange)="openStatusChangeDialog($event, row)"
                                >
                                </uav-id-shared-lib-status-badge-menu>
                                <button
                                    *ngIf="hasExtendedAccess"
                                    type="button"
                                    class="mobile-table-header-actions button-icon"
                                    [matMenuTriggerFor]="rowActionsMenu"
                                    [matMenuTriggerData]="{ $implicit: row }"
                                >
                                    <dtm-ui-icon name="more"></dtm-ui-icon>
                                </button>
                            </div>

                            <div class="mobile-table-content">
                                <dtm-ui-label-value [label]="'uavIdAdminLibOfficer.officerList.emailLabel' | transloco">
                                    <div class="row-value">
                                        {{ row.email }}
                                        <dtm-ui-popover
                                            *ngIf="row.type === UserType.DutyOfficer"
                                            class="duty-officer-icon"
                                            popoverIcon="shield-check"
                                            [popoverText]="'uavIdAdminLibOfficer.officerList.dutyOfficerLabel' | transloco"
                                        ></dtm-ui-popover>
                                    </div>
                                </dtm-ui-label-value>
                                <dtm-ui-label-value
                                    [label]="'uavIdAdminLibOfficer.officerList.phoneNumberLabel' | transloco"
                                    [value]="row.phoneNumber | formatPhoneNumber"
                                >
                                </dtm-ui-label-value>
                                <dtm-ui-label-value
                                    *ngIf="hasExtendedAccess"
                                    [label]="'uavIdAdminLibOfficer.officerList.officerUnitLabel' | transloco"
                                    [value]="row.officerUnit.name"
                                >
                                </dtm-ui-label-value>
                            </div>
                        </div>

                        <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
                        <ng-container
                            *ngIf="hasGetListError; else emptyListTemplate"
                            mobileTableNoData
                            [ngTemplateOutlet]="errorTemplate"
                        ></ng-container>
                    </dtm-ui-responsive-table>
                </dtm-ui-loader-container>

                <mat-menu #rowActionsMenu="matMenu">
                    <ng-template matMenuContent let-element>
                        <ng-container [ngSwitch]="element.type">
                            <button *ngSwitchCase="UserType.Officer" mat-menu-item type="button" (click)="promoteOfficer(element)">
                                <dtm-ui-icon name="shield-check"></dtm-ui-icon>
                                {{ "uavIdAdminLibOfficer.officerList.promoteOfficerLabel" | transloco }}
                            </button>
                            <button *ngSwitchCase="UserType.DutyOfficer" mat-menu-item type="button" (click)="demoteOfficer(element)">
                                <dtm-ui-icon name="shield"></dtm-ui-icon>
                                {{ "uavIdAdminLibOfficer.officerList.demoteOfficerLabel" | transloco }}
                            </button>
                        </ng-container>
                        <button *ngIf="hasExtendedAccess" mat-menu-item type="button" (click)="updateOfficerUnit(element)">
                            <dtm-ui-icon name="edit"></dtm-ui-icon>
                            {{ "uavIdAdminLibOfficer.officerList.updateOfficerUnitLabel" | transloco }}
                        </button>
                    </ng-template>
                </mat-menu>

                <ng-template #paginationTemplate>
                    <dtm-ui-pagination *ngIf="page" [pagination]="page" (page)="pageChange.emit($event)"> </dtm-ui-pagination>
                </ng-template>
                <ng-template #errorTemplate>
                    <dtm-ui-error
                        [errorMessage]="'uavIdAdminLibOfficer.officerList.getListErrorMessage' | transloco"
                        (reload)="pageReload.emit()"
                    ></dtm-ui-error>
                </ng-template>
                <ng-template #emptyListTemplate>
                    <dtm-ui-no-results></dtm-ui-no-results>
                </ng-template>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
