import { DOCUMENT } from "@angular/common";
import { APP_INITIALIZER, Inject, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, GeolocationService, SHARED_MAP_ENDPOINTS, WebGeolocationService } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { UAV_APP_URLS } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    getTranslocoInlineLoader,
    I18nRootModule,
    LanguageCode,
    LANGUAGE_CONFIGURATION,
    TranslationHelperService,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { FunctionUtils, LOCAL_STORAGE, Logger, LoggerModule } from "@dtm-frontend/shared/utils";
import { OfficerModule, OFFICER_ENDPOINTS } from "@dtm-frontend/uav-identification-admin-lib/officer";
import { ReportManagementModule } from "@dtm-frontend/uav-identification-admin-lib/report-management";
import { REPORT_MANAGEMENT_ENDPOINTS } from "@dtm-frontend/uav-identification-shared-lib/report";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { forkJoin } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { UavIdentificationAdminEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://63fe4fde3c68d3fab38c3cb29122b5c3@sentry.pansa.cloud/11");

export function createAppModule(environment: UavIdentificationAdminEnvironment) {
    @NgModule({
        declarations: [AppComponent, HeaderComponent, LazyRouterOutletComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            MatIconModule,
            MatProgressSpinnerModule,
            MatTooltipModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "UavIdentificationAdmin",
            }),
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            ReportManagementModule,
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            SharedToastModule.forRoot(),
            SharedUiModule,
            OfficerModule,
        ],
        providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "uavIdAdmin",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: REPORT_MANAGEMENT_ENDPOINTS,
                useValue: environment.reportManagementEndpoints,
            },
            {
                provide: OFFICER_ENDPOINTS,
                useValue: environment.officerEndpoints,
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: UAV_APP_URLS,
                useValue: environment.uavAppUrls,
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            { provide: LOCAL_STORAGE, useValue: localStorage },
            {
                provide: GeolocationService,
                useClass: WebGeolocationService,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(
            keycloakService: KeycloakService,
            store: Store,
            translocoHelper: TranslationHelperService,
            titleService: Title,
            @Inject(DOCUMENT) document: Document
        ) {
            // TODO: is this a good place for some initializing tasks? Maybe it should be extracted somewhere, use APP_INITIALIZER?
            const gotUserData$ = keycloakService.keycloakEvents$.pipe(
                filter((event) => event?.type === KeycloakEventType.OnReady),
                first()
            );

            const gotTitle$ = translocoHelper
                .waitForTranslation("uavIdAdmin.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            forkJoin([gotUserData$, gotTitle$])
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
