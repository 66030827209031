import { Params } from "@angular/router";
import { OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { OfficerStatusChange } from "../models/officer.models";

export namespace OfficerActions {
    export class GetLoggedInOfficerData {
        public static readonly type = "[Officer] Get logged in officer data";
    }

    export class GetOfficers {
        public static readonly type = "[Officer] Get officers";
        constructor(public queryParams?: Params) {}
    }

    export class GetAllOfficerUnits {
        public static readonly type = "[Officer] Get all officer units";
    }

    export class GetUniformedServiceOfficerUnits {
        public static readonly type = "[Officer] Get uniformed service officer units";
    }

    export class ChangeStatus {
        public static readonly type = "[Officer] Change status";
        constructor(public statusChange: OfficerStatusChange) {}
    }

    export class PromoteOfficer {
        public static readonly type = "[Officer] Promote officer";
        constructor(public officerId: string) {}
    }

    export class DemoteOfficer {
        public static readonly type = "[Officer] Demote officer";
        constructor(public officerId: string) {}
    }

    export class UpdateOfficerUnit {
        public static readonly type = "[Officer] Update officer unit";
        constructor(public officerId: string, public officerUnit: OfficerUnitEntity) {}
    }
}
