<ng-container *ngrxLet="areInitialFiltersProvided$ as areInitialFiltersPresent">
    <dtm-ui-filters-container
        *ngrxLet="appliedFiltersCount$ as appliedFiltersCount"
        [filtersCount]="appliedFiltersCount"
        [isExpanded]="areInitialFiltersPresent"
    >
        <form class="filters-container" autocomplete="off" [formGroup]="filtersFormGroup">
            <dtm-ui-input-field>
                <input
                    matInput
                    type="text"
                    [formControl]="lastNameControl"
                    [placeholder]="'uavIdAdminLibOfficer.officerListFilters.lastNameLabel' | transloco"
                />
            </dtm-ui-input-field>

            <dtm-ui-select-field multiple [formControl]="statusesControl">
                <label>{{ "uavIdAdminLibOfficer.officerListFilters.statusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let status of statuses$ | ngrxPush" [value]="status">{{
                    "uavIdAdminLibOfficer.officerStatusLabel" | transloco : { value: status }
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-select-field *ngIf="officerUnitsControl.enabled" multiple [formControl]="officerUnitsControl">
                <label>{{ "uavIdAdminLibOfficer.officerListFilters.officerUnitLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let officerUnit of officerUnits$ | ngrxPush" [value]="officerUnit.name">{{
                    officerUnit.name
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>

            <div *ngIf="areDutyOfficersOnlyControl.enabled" class="control-toggle">
                <span>{{ "uavIdAdminLibOfficer.officerListFilters.areDutyOfficersOnlyLabel" | transloco }}</span>
                <mat-slide-toggle [formControl]="areDutyOfficersOnlyControl"> </mat-slide-toggle>
            </div>
        </form>

        <dtm-ui-select-field
            class="sort-select"
            [formControl]="sortControl"
            [compareWith]="compareSelectOptions"
            [isClearable]="false"
            (selectionChange)="sortChange.emit($event.value)"
        >
            <label>{{ "uavIdAdminLibOfficer.officerListFilters.sortLabel" | transloco }}</label>
            <dtm-ui-select-option
                *ngFor="let sort of sortingOptions$ | ngrxPush"
                [value]="{ active: sort.active, direction: sort.direction }"
            >
                {{ sort.translateKey | transloco : { value: sort.direction } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>

        <dtm-ui-filter-chips-display
            selectedFiltersDisplaySlot
            [class.chips-margin]="!!appliedFiltersCount"
            [formGroup]="filtersFormGroup"
            [filtersValue]="filtersFormGroup.value"
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="appliedFiltersCount"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
