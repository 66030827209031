import { InjectionToken } from "@angular/core";

export interface OfficerEndpoints {
    getOfficerData: string;
    activateOfficer: string;
    deactivateOfficer: string;
    demoteOfficer: string;
    getOfficers: string;
    getAllOfficerUnits: string;
    getUniformedServiceOfficerUnits: string;
    promoteOfficer: string;
    rejectOfficer: string;
    updateOfficerUnit: string;
}

export const OFFICER_ENDPOINTS = new InjectionToken<OfficerEndpoints>("Officer endpoints");
