import { isFunction } from "./is-function";
import { isNullOrUndefined } from "./is-null-or-undefined";
import { isTruthy } from "./is-truthy";
import { noop } from "./noop-function";
import { throttle } from "./throttle.decorator";

export const FunctionUtils = {
    isFunction,
    noop,
    isTruthy,
    isNullOrUndefined,
    throttle,
};
export * from "./invoke.pipe";
