<ng-container *ngrxLet="appliedFiltersCount$; let appliedFiltersCount">
    <dtm-ui-filters-container [filtersCount]="appliedFiltersCount" [isExpanded]="areInitialFiltersProvided$ | ngrxPush">
        <form
            class="filters-container"
            autocomplete="off"
            [formGroup]="filtersFormGroup"
            *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
        >
            <dtm-ui-input-field>
                <input
                    matInput
                    type="text"
                    [formControl]="textControl"
                    [placeholder]="'uavIdAdminLibReportMgmt.reportListFilters.textSearchLabel' | transloco"
                />
            </dtm-ui-input-field>

            <dtm-ui-select-field *ngIf="officerUnitsControl.enabled" multiple [formControl]="officerUnitsControl">
                <label>{{ "uavIdAdminLibReportMgmt.reportListFilters.officerUnitLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let officerUnit of officerUnits$ | ngrxPush" [value]="officerUnit.name">{{
                    officerUnit.name
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-select-field multiple [formControl]="statusesControl">
                <label>{{ "uavIdAdminLibReportMgmt.reportListFilters.reportStatusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let reportStatus of statuses$ | ngrxPush" [value]="reportStatus">{{
                    "uavIdAdminLibReportMgmt.reportListFilters.reportStatusValueLabel" | transloco : { value: reportStatus }
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-date-field>
                <label>{{ "uavIdAdminLibReportMgmt.reportListFilters.createdDateFromLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="createdDateFromControl"
                    [matDatepicker]="fromPicker"
                    [placeholder]="datePickerPlaceholder"
                    [max]="createdDateToControl.value"
                />
                <mat-datepicker #fromPicker></mat-datepicker>
            </dtm-ui-date-field>

            <dtm-ui-date-field>
                <label>{{ "uavIdAdminLibReportMgmt.reportListFilters.createdDateToLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="createdDateToControl"
                    [matDatepicker]="toPicker"
                    [placeholder]="datePickerPlaceholder"
                    [min]="createdDateFromControl.value"
                />
                <mat-datepicker #toPicker></mat-datepicker>
            </dtm-ui-date-field>

            <div class="toggle-container">
                <div class="control-toggle">
                    <span>{{ "uavIdAdminLibReportMgmt.reportListFilters.isCreatedByOfficerLabel" | transloco }}</span>
                    <mat-slide-toggle [formControl]="isCreatedByOfficerControl"> </mat-slide-toggle>
                </div>

                <div class="control-toggle">
                    <span>{{ "uavIdAdminLibReportMgmt.reportListFilters.isEmergencyReportLabel" | transloco }}</span>
                    <mat-slide-toggle [formControl]="isEmergencyReportControl"> </mat-slide-toggle>
                </div>
            </div>
        </form>

        <dtm-ui-select-field
            class="sort-select"
            [formControl]="sortControl"
            [compareWith]="compareSelectOptions"
            [isClearable]="false"
            (selectionChange)="sortChange.emit($event.value)"
        >
            <label>{{ "uavIdAdminLibReportMgmt.reportListFilters.sortLabel" | transloco }}</label>
            <dtm-ui-select-option
                *ngFor="let sort of sortingOptions$ | ngrxPush"
                [value]="{ active: sort.active, direction: sort.direction }"
                >{{ sort.translateKey | transloco : { value: sort.direction } }}</dtm-ui-select-option
            >
        </dtm-ui-select-field>

        <dtm-ui-filter-chips-display
            selectedFiltersDisplaySlot
            [class.chips-margin]="!!appliedFiltersCount"
            [formGroup]="filtersFormGroup"
            [filtersValue]="filtersFormGroup.value"
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="appliedFiltersCount"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
