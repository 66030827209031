import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    AbstractControl,
    AsyncValidatorFn,
    ControlValueAccessor,
    NgControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { map, take } from "rxjs/operators";

interface TimeFieldComponentState {
    referenceDate: Date;
    minTime: Date | undefined | null;
    maxTime: Date | undefined | null;
    isClearable: boolean;
    isRequired: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-time-field",
    templateUrl: "./time-field.component.html",
    styleUrls: ["./time-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeFieldComponent),
            multi: true,
        },
    ],
})
export class TimeFieldComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit {
    public readonly timeFormControl = new UntypedFormControl("");
    public readonly isClearable$ = this.localStore.selectByKey("isClearable");
    public readonly isRequired$ = this.localStore.selectByKey("isRequired");

    @Input()
    public set minTime(value: Date | undefined | null) {
        if (!FunctionUtils.isNullOrUndefined(value)) {
            value.setSeconds(0, 0); // Time field validates only hours and minutes
        }

        this.localStore.patchState({ minTime: value });
        this.updateValidity();
    }

    @Input()
    public set maxTime(value: Date | undefined | null) {
        if (!FunctionUtils.isNullOrUndefined(value)) {
            value.setSeconds(0, 0); // Time field validates only hours and minutes
        }

        this.localStore.patchState({ maxTime: value });
        this.updateValidity();
    }

    /**
     * Reference date is used to create date object for comparisons of min/max time values.
     * If not provided current date will be used.
     */
    @Input()
    public set referenceDate(value: Date | undefined | null) {
        if (!value) {
            return;
        }

        this.localStore.patchState({ referenceDate: value });
        this.updateValidity();
    }

    @Input()
    public set isClearable(value: boolean) {
        this.localStore.patchState({ isClearable: value });
    }

    @Input()
    public set required(value: boolean | string) {
        const requiredValue = coerceBooleanProperty(value);

        this.localStore.patchState({ isRequired: requiredValue });
    }

    @Output() public readonly valueChange = new EventEmitter<Date | null>();

    private propagateChange: (value: Date | null) => void = FunctionUtils.noop;
    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(private readonly injector: Injector, private readonly localStore: LocalComponentStore<TimeFieldComponentState>) {
        this.localStore.setState({
            referenceDate: new Date(),
            minTime: undefined,
            maxTime: undefined,
            isClearable: false,
            isRequired: false,
        });
    }

    public ngOnInit(): void {
        this.timeFormControl.setAsyncValidators(this.validateTime());
        this.watchTimeValueChanges();
    }

    public ngAfterViewInit() {
        const parentControl = this.injector.get(NgControl, null)?.control;
        parentControl?.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
            this.timeFormControl.setErrors(status === "INVALID" ? { wrongTime: true } : null);
        });
    }

    private updateValidity(): void {
        if (!this.timeFormControl.disabled) {
            this.timeFormControl.updateValueAndValidity();
        }
    }

    private watchTimeValueChanges(): void {
        this.timeFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            const selectedDateTime = this.createDateFromTime(value) ?? null;

            this.propagateChange(selectedDateTime);
            this.onValidationChange();
            this.valueChange.emit(selectedDateTime);
        });
    }

    private createDateFromTime(timeControlValue: string): Date | undefined {
        const [hours, minutes] = timeControlValue.split(":");

        if (!hours || !minutes) {
            return;
        }

        const referenceDate = this.localStore.selectSnapshotByKey("referenceDate");
        referenceDate.setHours(+hours, +minutes);

        return referenceDate;
    }

    private validateTime(): AsyncValidatorFn {
        return (timeControl: AbstractControl) =>
            combineLatest([this.localStore.selectByKey("minTime"), this.localStore.selectByKey("maxTime")]).pipe(
                map(([minTime, maxTime]) => {
                    const selectedDateTime = this.createDateFromTime(timeControl.value);

                    if (!selectedDateTime) {
                        return null;
                    }

                    if (minTime && selectedDateTime < minTime) {
                        return { min: { min: minTime, actual: selectedDateTime } };
                    }

                    if (maxTime && selectedDateTime > maxTime) {
                        return { max: { max: maxTime, actual: selectedDateTime } };
                    }

                    return null;
                }),
                take(1),
                untilDestroyed(this)
            );
    }

    private formatNumberValue(value: number): string {
        return `${value}`.padStart(2, "0");
    }

    public registerOnChange(fn: (value: Date | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: Date | undefined): void {
        if (!value) {
            this.timeFormControl.setValue("");

            return;
        }

        this.referenceDate = value;

        const hours = value.getHours();
        const minutes = value.getMinutes();

        this.timeFormControl.setValue(`${this.formatNumberValue(hours)}:${this.formatNumberValue(minutes)}`);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.timeFormControl.disable();
        } else {
            this.timeFormControl.enable();
        }
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public validate(): ValidationErrors | null {
        if (this.timeFormControl.invalid) {
            return this.timeFormControl.errors;
        }

        return null;
    }
}
