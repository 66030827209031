<div class="grid" *ngrxLet="hasExtendedAccess$ as hasExtendedAccess">
    <ng-container *ngrxLet="statuses$ as statuses">
        <uav-id-admin-lib-report-list-filters
            [statuses]="statuses"
            [initialFilters]="initialFilters$ | ngrxPush"
            [sort]="listSorting$ | ngrxPush"
            [hasExtendedAccess]="hasExtendedAccess"
            [officerUnits]="uniformedServiceOfficerUnits$ | ngrxPush"
            (filtersChange)="applyFilters($event)"
            (sortChange)="sortList($event, true)"
        ></uav-id-admin-lib-report-list-filters>
        <uav-id-admin-lib-report-list
            [reports]="reports$ | ngrxPush"
            [reportsPage]="reportsPage$ | ngrxPush"
            [isProcessing]="isProcessing$ | ngrxPush"
            [hasGetListErrorOccurred]="hasGetListErrorOccurred$ | ngrxPush"
            [sort]="listSorting$ | ngrxPush"
            [hasExtendedAccess]="hasExtendedAccess"
            (interventionDialogOpen)="openInterventionNoteDialog($event)"
            (officerAssign)="openAssignOfficerDialog($event)"
            (statusChange)="updateRowStatus($event)"
            (pageChange)="changePage($event)"
            (sortChange)="sortList($event)"
            (pageReload)="getReportList()"
            (officerUnitChange)="openAssignOfficerUnitDialog($event)"
        ></uav-id-admin-lib-report-list>
    </ng-container>
</div>
