import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { OfficerListContainerComponent } from "./components/officer-list-container/officer-list-container.component";

const routes: Routes = [
    {
        path: "officers",
        component: OfficerListContainerComponent,
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OfficerRoutingModule {}
