import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { map } from "rxjs";

export interface AssignOfficerUnitDialogData {
    officerUnit: OfficerUnitEntity;
    officerUnits: OfficerUnitEntity[];
    titleText?: string;
    confirmationText?: string;
    confirmButtonLabel?: string;
    declineButtonLabel?: string;
    isInitialAssign?: boolean;
}

interface AssignOfficerUnitDialogComponentState {
    officerUnits: OfficerUnitEntity[] | undefined;
}

@Component({
    selector: "uav-id-admin-lib-assign-officer-unit-dialog",
    templateUrl: "./assign-officer-unit-dialog.component.html",
    styleUrls: ["../../styles/uav-identification-admin-lib-dialog.scss", "./assign-officer-unit-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AssignOfficerUnitDialogComponent {
    protected readonly officerUnits$ = this.localStore.selectByKey("officerUnits");
    protected readonly officerUnitControl = new FormControl<OfficerUnitEntity>(this.data.officerUnit, [Validators.required]);
    protected readonly hasAssignedOfficerUnitChanged$ = this.officerUnitControl.valueChanges.pipe(
        map((value) => value?.id !== this.data.officerUnit.id)
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: AssignOfficerUnitDialogData,
        private readonly dialog: MatDialogRef<AssignOfficerUnitDialogComponent>,
        private readonly localStore: LocalComponentStore<AssignOfficerUnitDialogComponentState>
    ) {
        this.localStore.setState({ officerUnits: data.officerUnits });
    }

    protected submit() {
        this.dialog.close(this.officerUnitControl.value);
    }

    protected cancel() {
        this.dialog.close();
    }

    protected compareOfficerUnits(unit1: OfficerUnitEntity, unit2: OfficerUnitEntity): boolean {
        return unit1?.id === unit2?.id;
    }
}
