import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ReportSummaryResolver } from "@dtm-frontend/uav-identification-shared-lib/report";
import { ReportListContainerComponent } from "./components/report-list-container/report-list-container.component";
import { ReportPreviewComponent } from "./components/report-preview/report-preview.component";

const routes: Routes = [
    {
        path: "reports",
        component: ReportListContainerComponent,
        canActivate: [DtmRoleGuard],
    },
    {
        path: "reports/:id",
        component: ReportPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: ReportSummaryResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [ReportSummaryResolver],
    exports: [RouterModule],
})
export class ReportManagementRoutingModule {}
