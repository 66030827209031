import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatSortModule } from "@angular/material/sort";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { ReportManagementState, ReportSharedModule } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdentificationSharedModule } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ReportListContainerComponent } from "./components/report-list-container/report-list-container.component";
import { ReportListFiltersComponent } from "./components/report-list-filters/report-list-filters.component";
import { ReportListComponent } from "./components/report-list/report-list.component";
import { ReportPreviewComponent } from "./components/report-preview/report-preview.component";
import { OfficerAssignDialogComponent } from "./dialogs/officer-assign-dialog/officer-assign-dialog.component";
import { OfficerAssignDialogService } from "./dialogs/officer-assign-dialog/officer-assign-dialog.service";
import { ReportManagementRoutingModule } from "./report-management-routing.module";

@NgModule({
    imports: [
        CommonModule,
        LeafletMapModule,
        LetModule,
        MatDatepickerModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        NgxsModule.forFeature([ReportManagementState]),
        PushModule,
        ReactiveFormsModule,
        ReportManagementRoutingModule,
        ReportSharedModule,
        SharedI18nModule,
        SharedUiModule,
        UavIdentificationSharedModule,
    ],
    declarations: [
        ReportListComponent,
        ReportListContainerComponent,
        ReportListFiltersComponent,
        ReportPreviewComponent,
        OfficerAssignDialogComponent,
    ],
    providers: [
        OfficerAssignDialogService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdAdminLibReportMgmt",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class ReportManagementModule {}
