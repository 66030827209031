<div class="grid" *ngrxLet="{ hasExtendedAccess: hasExtendedAccess$, listSort: listSort$ } as vm">
    <uav-id-admin-lib-officer-list-filters
        [statuses]="statuses$ | ngrxPush"
        [sort]="vm.listSort"
        [officerUnits]="uniformedServiceOfficerUnits$ | ngrxPush"
        [initialFilters]="initialFilters"
        [hasExtendedAccess]="vm.hasExtendedAccess"
        (filtersChange)="applyFilters($event)"
        (sortChange)="sortList($event, true)"
    ></uav-id-admin-lib-officer-list-filters>
    <uav-id-admin-lib-officer-list
        [officers]="officers$ | ngrxPush"
        [officersPage]="officersPage$ | ngrxPush"
        [officerUnits]="officerUnits$ | ngrxPush"
        [hasGetListError]="hasGetListError$ | ngrxPush"
        [hasExtendedAccess]="vm.hasExtendedAccess"
        [isProcessing]="isProcessing$ | ngrxPush"
        [sort]="vm.listSort"
        (pageChange)="changePage($event)"
        (pageReload)="getOfficerList()"
        (sortChange)="sortList($event)"
        (statusChange)="changeStatus($event)"
        (officerPromotion)="promoteOfficer($event)"
        (officerDemotion)="demoteOfficer($event)"
        (officerUnitChange)="updateOfficerUnit($event)"
    ></uav-id-admin-lib-officer-list>
</div>
