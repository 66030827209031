<div class="dialog-header">
    <h2 mat-dialog-title>{{ data.titleText ?? ("uavIdAdminLibShared.assignOfficerUnitDialog.title" | transloco) }}</h2>
    <button type="button" class="button-icon" (click)="cancel()">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<p *ngIf="data.confirmationText" class="confirmation-text">
    {{ data.confirmationText }}
</p>

<div mat-dialog-content>
    <dtm-ui-select-field
        *ngIf="officerUnitControl.enabled"
        class="form-item"
        [formControl]="officerUnitControl"
        [compareWith]="compareOfficerUnits"
        [isClearable]="false"
    >
        <label>{{ "uavIdAdminLibShared.assignOfficerUnitDialog.officerUnitLabel" | transloco }}</label>
        <dtm-ui-select-option *ngFor="let officerUnit of officerUnits$ | ngrxPush" [value]="officerUnit">
            {{ officerUnit.name }}
        </dtm-ui-select-option>
        <div class="field-error" *dtmUiFieldHasError="officerUnitControl; name: 'required'">
            {{ "uavIdAdminLibShared.assignOfficerUnitDialog.fieldRequiredErrorMessage" | transloco }}
        </div>
    </dtm-ui-select-field>
</div>

<div mat-dialog-actions>
    <button type="button" class="button-secondary" (click)="cancel()">
        {{ data.declineButtonLabel ?? ("uavIdAdminLibShared.assignOfficerUnitDialog.declineButtonLabel" | transloco) }}
    </button>
    <button
        type="submit"
        class="button-primary"
        [disabled]="(!(hasAssignedOfficerUnitChanged$ | ngrxPush) && !data.isInitialAssign) || officerUnitControl.invalid"
        (click)="submit()"
    >
        <span>{{ data.confirmButtonLabel ?? ("uavIdAdminLibShared.assignOfficerUnitDialog.confirmButtonLabel" | transloco) }}</span>
    </button>
</div>
