import { GeoJSON } from "leaflet";

export enum FlightErrorType {
    Unknown = "Unknown",
}

export interface FlightError {
    type: FlightErrorType;
}

export interface FlightSearchArea {
    center: {
        longitude: number;
        latitude: number;
    };
    radius: number;
}

export enum FlightType {
    Checkin = "CHECKIN",
    Mission = "MISSION",
}

export enum FlightCategory {
    Specific = "SPECIFIC",
    Open = "OPEN",
    Certified = "CERTIFIED",
}

export interface Flight {
    id: string;
    type: FlightType;
    startTime: Date;
    endTime: Date;
    routeVolume: FlightRouteVolume;
    operator?: FlightOperator;
    pilot?: FlightPilot;
    flightDetails?: FlightDetails;
    uav?: FlightUav;
    activatedAt?: Date;
}

interface FlightRouteVolume {
    flightArea: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon;
    radius: number;
    altitudeRange?: FlightAltitudeRange;
}

interface FlightAltitudeRange {
    floor: number;
    ceiling: number;
}

interface FlightOperator {
    number: string;
    name: string;
}

interface FlightPilot {
    number: string;
    phoneNumber: string;
}

interface FlightDetails {
    name: string;
    category: FlightCategory;
    subcategory: string;
    type: string;
    extraInfo: string;
}

interface FlightUav {
    id: string;
    name: string;
    model: FlightUavModel;
}

interface FlightUavModel {
    id: string;
    name: string;
    manufacturer: string;
}

export enum FlightEvent {
    FlightCreated = "FlightCreated",
    FlightUpdated = "FlightUpdated",
    FlightRemoved = "FlightRemoved",
}

export type FlightEventMessage =
    | { type: FlightEvent.FlightCreated | FlightEvent.FlightUpdated; body: Flight }
    | { type: FlightEvent.FlightRemoved; body: { id: string } };
