/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";

type FnRest<FirstArgumentType, FunctionType extends (first: FirstArgumentType, ...args: any[]) => any> = FunctionType extends (
    first: FirstArgumentType,
    ...args: infer ParametersType
) => ReturnType<FunctionType>
    ? ParametersType
    : never;

@Pipe({
    name: "invoke",
})
export class InvokePipe implements PipeTransform {
    public transform<FirstArgumentType, FunctionType extends (first: FirstArgumentType, ...args: any[]) => any>(
        value: FirstArgumentType,
        fn: FunctionType,
        ...rest: FnRest<FirstArgumentType, FunctionType>
    ): ReturnType<FunctionType> {
        return fn(value, ...rest);
    }
}
