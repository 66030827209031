import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DeviceSize, DeviceSizeService, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { Store } from "@ngxs/store";

@Component({
    selector: "uav-id-admin-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    public readonly isCollapsed$ = this.store.select(UIState.isMenuCollapsed);

    constructor(private readonly store: Store, private readonly deviceSizeService: DeviceSizeService) {}

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        }
    }
}
