import { ChangeDetectionStrategy, Component, ContentChildren, QueryList } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { CarouselSlidesDirective } from "./carousel-slides.directive";

interface CarouselComponentState {
    currentSlide: number;
}

@Component({
    selector: "dtm-ui-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CarouselComponent {
    @ContentChildren(CarouselSlidesDirective) protected readonly slides: QueryList<CarouselSlidesDirective> =
        new QueryList<CarouselSlidesDirective>();

    protected readonly currentSlide$ = this.localStore.selectByKey("currentSlide");

    constructor(private readonly localStore: LocalComponentStore<CarouselComponentState>) {
        localStore.setState({ currentSlide: Math.max(Math.min(0, this.slides.length - 1), 0) });
    }

    public previousSlide(): void {
        const currentSlide = this.localStore.selectSnapshotByKey("currentSlide");

        if (currentSlide <= 0) {
            this.localStore.patchState({ currentSlide: this.slides.length - 1 });
        } else {
            this.localStore.patchState({ currentSlide: currentSlide - 1 });
        }
    }

    public nextSlide(): void {
        const currentSlide = this.localStore.selectSnapshotByKey("currentSlide");

        if (currentSlide >= this.slides.length - 1) {
            this.localStore.patchState({ currentSlide: 0 });
        } else {
            this.localStore.patchState({ currentSlide: currentSlide + 1 });
        }
    }
}
