<section *ngIf="flight$ | ngrxPush as flight">
    <section *ngrxLet="hasExtendedDetails$ as hasExtendedDetails" [ngSwitch]="flight.type" class="container">
        <ng-container *ngSwitchCase="FlightType.Checkin">
            <div class="header">
                <div *ngIf="hasExtendedDetails && flight.flightDetails?.extraInfo">{{ flight.flightDetails?.extraInfo }}</div>
                <div>{{ flight | invoke : getCheckinCoordinates }}</div>
            </div>

            <ng-container
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.startTimeLabel' | transloco,
                    value: flight.startTime | localizeDate : { dateStyle: 'medium', timeStyle: 'short' }
                }"
            ></ng-container>
            <ng-container
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.endTimeLabel' | transloco,
                    value: flight.endTime | localizeDate : { dateStyle: 'medium', timeStyle: 'short' }
                }"
            ></ng-container>
            <ng-container
                *ngIf="flight.routeVolume.altitudeRange?.floor"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.maxHeightLabel' | transloco,
                    value: 'uavIdSharedLibShared.flight.heightValueLabel' | transloco : { height: flight.routeVolume.altitudeRange?.floor }
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.flightDetails?.category"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.categoryLabel' | transloco,
                    value: 'uavIdSharedLibShared.flight.categoryValueLabel' | transloco : { value: flight.flightDetails?.category }
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.flightDetails?.subcategory"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.subcategoryLabel' | transloco,
                    value: flight.flightDetails?.subcategory
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.pilot?.phoneNumber"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotPhoneNumberLabel' | transloco,
                    value: flight.pilot?.phoneNumber
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.pilot?.number"
                [ngTemplateOutlet]="linkPropertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotNumberLabel' | transloco,
                    value: flight.pilot?.number,
                    link: flight.pilot?.number | invoke : getPilotProfileLink : uavAppUrls
                }"
            ></ng-container>
            <ng-container
                *ngIf="!hasExtendedDetails && flight.pilot?.number"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotNumberLabel' | transloco,
                    value: flight.pilot?.number,
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.operator?.number"
                [ngTemplateOutlet]="linkPropertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.operatorNumberLabel' | transloco,
                    value: flight.operator?.number,
                    link: flight.operator?.number | invoke : getOperatorProfileLink : uavAppUrls
                }"
            ></ng-container>
            <ng-container
                *ngIf="!hasExtendedDetails && flight.operator?.number"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.operatorNumberLabel' | transloco,
                    value: flight.operator?.number
                }"
            ></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FlightType.Mission">
            <div class="header">
                <div>{{ flight.flightDetails?.name }}</div>
                <div>{{ flight.flightDetails?.type }}</div>
            </div>

            <ng-container
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.startTimeLabel' | transloco,
                    value: flight.startTime | localizeDate : { dateStyle: 'medium', timeStyle: 'short' }
                }"
            ></ng-container>
            <ng-container
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.endTimeLabel' | transloco,
                    value: flight.endTime | localizeDate : { dateStyle: 'medium', timeStyle: 'short' }
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.activatedAtLabel' | transloco,
                    value: flight.activatedAt | localizeDate : { dateStyle: 'medium', timeStyle: 'short' }
                }"
            ></ng-container>
            <ng-container
                *ngIf="flight.routeVolume.altitudeRange?.floor"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.minHeightLabel' | transloco,
                    value: 'uavIdSharedLibShared.flight.heightValueLabel' | transloco : { height: flight.routeVolume.altitudeRange?.floor }
                }"
            ></ng-container>
            <ng-container
                *ngIf="flight.routeVolume.altitudeRange?.ceiling"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.maxHeightLabel' | transloco,
                    value:
                        'uavIdSharedLibShared.flight.heightValueLabel' | transloco : { height: flight.routeVolume.altitudeRange?.ceiling }
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.categoryLabel' | transloco,
                    value: 'uavIdSharedLibShared.flight.categoryValueLabel' | transloco : { value: flight.flightDetails?.category }
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.flightDetails?.subcategory"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.subcategoryLabel' | transloco,
                    value: flight.flightDetails?.subcategory
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.uav"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.uavLabel' | transloco,
                    value: flight.uav.model.manufacturer + ' ' + flight.uav.model.name
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.pilot?.phoneNumber"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotPhoneNumberLabel' | transloco,
                    value: flight.pilot?.phoneNumber
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.pilot?.number"
                [ngTemplateOutlet]="linkPropertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotNumberLabel' | transloco,
                    value: flight.pilot?.number,
                    link: flight.pilot?.number | invoke : getPilotProfileLink : uavAppUrls
                }"
            ></ng-container>
            <ng-container
                *ngIf="!hasExtendedDetails && flight.pilot?.number"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.pilotNumberLabel' | transloco,
                    value: flight.pilot?.number,
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.operator?.name"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.operatorNameLabel' | transloco,
                    value: flight.operator?.name
                }"
            ></ng-container>
            <ng-container
                *ngIf="hasExtendedDetails && flight.operator?.number"
                [ngTemplateOutlet]="linkPropertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.operatorNumberLabel' | transloco,
                    value: flight.operator?.number,
                    link: flight.operator?.number | invoke : getOperatorProfileLink : uavAppUrls
                }"
            ></ng-container>
            <ng-container
                *ngIf="!hasExtendedDetails && flight.operator?.number"
                [ngTemplateOutlet]="propertyTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdSharedLibShared.flight.operatorNumberLabel' | transloco,
                    value: flight.operator?.number
                }"
            ></ng-container>
        </ng-container>
    </section>
</section>

<ng-template #propertyTemplate let-label="label" let-value="value">
    <div class="property" *ngIf="value">
        <span class="label">{{ label }}</span>
        <span>{{ value }}</span>
    </div>
</ng-template>

<ng-template #linkPropertyTemplate let-label="label" let-value="value" let-link="link">
    <div class="property">
        <span class="label">{{ label }}</span>
        <a class="link" target="_blank" [href]="link">{{ value }}</a>
    </div>
</ng-template>
