import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { PushModule } from "@ngrx/component";
import { AssignOfficerUnitDialogComponent } from "./components/assign-officer-unit-dialog/assign-officer-unit-dialog.component";

@NgModule({
    imports: [CommonModule, MatDialogModule, PushModule, ReactiveFormsModule, SharedI18nModule, SharedUiModule],
    declarations: [AssignOfficerUnitDialogComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdAdminLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class SharedModule {}
