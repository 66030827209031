import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UserEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { map } from "rxjs";

interface OfficerAssignDialogData {
    assignedOfficer: UserEntity;
    officers: UserEntity[];
}

interface OfficerAssignDialogComponentState {
    officers: UserEntity[];
}

@Component({
    selector: "uav-id-admin-lib-officer-assign-dialog",
    templateUrl: "./officer-assign-dialog.component.html",
    styleUrls: ["../../../shared/styles/uav-identification-admin-lib-dialog.scss", "./officer-assign-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OfficerAssignDialogComponent {
    protected readonly officers$ = this.localStore.selectByKey("officers");

    protected readonly assignedOfficerControl = new FormControl(this.data.assignedOfficer, [Validators.required]);
    protected readonly hasAssignedOfficerChanged$ = this.assignedOfficerControl.valueChanges.pipe(
        map((value) => value?.id !== this.data.assignedOfficer?.id)
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) protected readonly data: OfficerAssignDialogData,
        private readonly dialog: MatDialogRef<OfficerAssignDialogComponent>,
        private readonly localStore: LocalComponentStore<OfficerAssignDialogComponentState>
    ) {
        this.localStore.setState({ officers: this.data.officers });
    }

    protected closeDialog(isConfirmed = false): void {
        this.dialog.close({ isConfirmed, assignedOfficer: this.assignedOfficerControl.value });
    }

    protected compareOfficers(officer1?: UserEntity, officer2?: UserEntity): boolean {
        return officer1?.id === officer2?.id;
    }

    protected displayOfficerFullName(officer: UserEntity): string {
        return `${officer.firstName} ${officer.lastName}`;
    }
}
