import { Params } from "@angular/router";
import { MIN_PAGE_SIZE_VALUE, PageResponseBody } from "@dtm-frontend/shared/ui";
import { OfficerEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { OfficerList, OfficerListQueryParams } from "../models/officer.models";

export type OfficerListResponseBody = PageResponseBody<OfficerEntity>;

export function convertOfficerListRequestParamsToRequestParams(queryParams: Partial<OfficerListQueryParams>): Params {
    const { lastName, statuses, officerUnits, areDutyOfficersOnly, active, direction, page, size } = queryParams ?? {};
    const sortPropertyName = (() => {
        switch (active) {
            case "officerUnit":
                return "officerUnit.name";
            default:
                return active;
        }
    })();
    const sort = `${sortPropertyName},${direction}`;

    return {
        ...(lastName && { lastName }),
        ...(statuses && { statuses }),
        ...(officerUnits && { officerUnitsIds: officerUnits }),
        ...(areDutyOfficersOnly && { onlyDutyOfficers: areDutyOfficersOnly }),
        ...(active && direction && { sort }),
        page: page ?? 0,
        size: size ?? MIN_PAGE_SIZE_VALUE,
    };
}

export function convertOfficerListResponseBodyToOfficerList(response: OfficerListResponseBody): OfficerList {
    return {
        content: response.content.map(({ id, firstName, lastName, email, phoneNumber, officerUnit, officerStatus, type }) => ({
            id,
            firstName,
            lastName,
            email,
            phoneNumber,
            officerUnit,
            type,
            status: officerStatus,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
