<section class="header">
    <button type="button" class="button-secondary" routerLink="..">
        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        {{ "uavIdAdminLibReportMgmt.reportPreview.goBackToListButtonLabel" | transloco }}
    </button>
</section>

<ng-container
    *ngrxLet="{
        reportSummary: reportSummary$,
        hasGetReportSummaryErrorOccurred: hasGetReportSummaryErrorOccurred$,
    } as vm"
>
    <ng-container *ngIf="!vm.hasGetReportSummaryErrorOccurred; else errorMessageTemplate">
        <section *ngIf="vm.reportSummary?.report as report" class="content grid">
            <div class="data-panel grid-col-desktop-wide-4 grid-col-desktop-6">
                <div class="data-panel-content">
                    <section class="data-panel-header">
                        <h2>{{ "uavIdAdminLibReportMgmt.reportPreview.reportHeaderLabel" | transloco : { number: report.number } }}</h2>
                        <button
                            *ngIf="!report.interventionNote"
                            type="button"
                            class="button-secondary"
                            (click)="openInterventionNoteDialog(report)"
                        >
                            <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
                            {{ "uavIdAdminLibReportMgmt.reportPreview.addNotesButtonLabel" | transloco }}
                        </button>
                    </section>

                    <uav-id-shared-lib-report-preview-intervention-note
                        *ngIf="report.interventionNote"
                        [report]="report"
                        (dialogOpen)="openInterventionNoteDialog(report)"
                    ></uav-id-shared-lib-report-preview-intervention-note>

                    <uav-id-shared-lib-report-preview-officer
                        [officer]="report.assignedOfficer"
                        (officerAssign)="openAssignOfficerDialog(report)"
                    >
                    </uav-id-shared-lib-report-preview-officer>

                    <uav-id-shared-lib-report-preview-user [user]="report.reportingUser">
                        <dtm-ui-popover
                            *ngIf="report.isCreatedByOfficer"
                            headerAdditionalContent
                            class="officer-icon"
                            [popoverText]="'uavIdAdminLibReportMgmt.report.createdByOfficerTooltipMessage' | transloco"
                            [popoverIcon]="'shield-star'"
                        ></dtm-ui-popover>
                    </uav-id-shared-lib-report-preview-user>

                    <uav-id-shared-lib-report-preview-details
                        [report]="report"
                        [canChangeStatus]="true"
                        [isStatusUpdateProcessing]="false"
                        (statusUpdate)="updateStatus($event, report)"
                    >
                        <dtm-ui-popover
                            *ngIf="report.isEmergencyReport"
                            headerAdditionalContent
                            class="emergency-icon"
                            [popoverText]="'uavIdAdminLibReportMgmt.report.emergencyReportTooltipMessage' | transloco"
                            [popoverIcon]="'error-warning'"
                        ></dtm-ui-popover>
                    </uav-id-shared-lib-report-preview-details>
                </div>
            </div>

            <uav-id-shared-lib-report-preview-map
                class="grid-col-desktop-wide-8 grid-col-desktop-6"
                [report]="report"
                [flights]="vm.reportSummary?.flights"
                [remoteIds]="vm.reportSummary?.remoteIds"
                [hasExtendedDetails]="true"
                [zonesLayerConfig]="REPORT_GEO_ZONES_LAYER_CONFIG"
                [isZonesInfoProcessing]="isSelectedReportGeoZonesInfoLoading$ | ngrxPush"
                [zonesInfo]="selectedReportGeoZonesInfo$ | ngrxPush"
                [zonesError]="selectedReportGeoZonesInfoError$ | ngrxPush"
                (zonesSelect)="getSelectedZonesInfo($event)"
                (zoneDetailsOpen)="openZoneDetailsDialog($event)"
            >
            </uav-id-shared-lib-report-preview-map>
        </section>
    </ng-container>
</ng-container>

<ng-template #errorMessageTemplate>
    <dtm-ui-error [errorMessage]="'uavIdAdminLibReportMgmt.reportPreview.unknownError' | transloco"></dtm-ui-error>
</ng-template>

<dtm-ui-loader [shouldSetParentPositionRelative]="true" [isShown]="isProcessing$ | ngrxPush"></dtm-ui-loader>
