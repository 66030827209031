import { OfficerStatus, StatusBadgeConfiguration, StatusBadgeTheme } from "@dtm-frontend/uav-identification-shared-lib/shared";

export const OFFICER_BADGE_CONFIGURATION: StatusBadgeConfiguration = {
    translationKey: "uavIdAdminLibOfficer.officerStatusLabel",
    badges: [
        {
            status: OfficerStatus.PendingActivation,
            icon: "time",
            theme: StatusBadgeTheme.Secondary,
        },
        {
            status: OfficerStatus.Active,
            icon: "checkbox-circle",
            theme: StatusBadgeTheme.Success,
        },
        {
            status: OfficerStatus.Rejected,
            icon: "close-circle",
            theme: StatusBadgeTheme.Error,
        },
        {
            status: OfficerStatus.Inactive,
            icon: "forbid",
            theme: StatusBadgeTheme.Disabled,
        },
    ],
};
