<ng-container *ngrxLet="isCollapsed$; let isCollapsed">
    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-link="link">
        <a
            [routerLink]="link"
            routerLinkActive="active-route"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>

    <ul class="menu-list">
        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdAdmin.menu.reportListMenuItemLabel' | transloco,
                    icon: 'file-list',
                    link: '/reports'
                }"
            ></ng-template>
        </li>

        <li>
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'uavIdAdmin.menu.officerListMenuItemLabel' | transloco,
                    icon: 'file-user',
                    link: '/officers'
                }"
            ></ng-template>
        </li>
    </ul>
</ng-container>
