import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, Logger } from "@dtm-frontend/shared/utils";
import { OfficerEntity, OfficerStatus, UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, firstValueFrom, map, switchMap } from "rxjs";
import { OfficerList, OfficerListFilters, OfficerStatusChange, OfficerUnitChange } from "../../models/officer.models";
import { OfficerActions } from "../../state/officer.actions";
import { OfficerState } from "../../state/officer.state";

interface OfficerListContainerComponentState {
    statuses: OfficerStatus[];
}

@UntilDestroy()
@Component({
    selector: "uav-id-admin-lib-officer-list-container",
    templateUrl: "./officer-list-container.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class OfficerListContainerComponent {
    protected readonly hasExtendedAccess$ = this.store.select(AuthState.roles).pipe(map((roles) => roles?.includes(UavIdRole.Admin)));
    protected readonly officers$ = this.store.select(OfficerState.officers);
    protected readonly officersPage$ = this.store.select(OfficerState.officersPage);
    protected readonly hasGetListError$ = this.store.select(OfficerState.getOfficersError).pipe(map(FunctionUtils.isTruthy));
    protected readonly isProcessing$ = this.store.select(OfficerState.isProcessing);
    protected readonly officerUnits$ = this.store.select(OfficerState.officerUnits);
    protected readonly uniformedServiceOfficerUnits$ = this.store.select(OfficerState.uniformedServiceOfficerUnits);
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly listSort$ = this.route.queryParams.pipe(
        map(({ active, direction }) => ({ active, direction })),
        distinctUntilChanged()
    );

    protected initialFilters: OfficerListFilters;

    constructor(
        private readonly dialog: MatDialog,
        private readonly localStore: LocalComponentStore<OfficerListContainerComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.store.dispatch(new OfficerActions.GetAllOfficerUnits());
        this.store.dispatch(new OfficerActions.GetUniformedServiceOfficerUnits());

        const { lastName = null, statuses = null, officerUnits = null, onlyDutyOfficers = null } = this.route.snapshot.queryParams ?? {};

        this.initialFilters = {
            lastName,
            statuses: statuses?.split(",") ?? [],
            officerUnits: officerUnits?.split(",") ?? [],
            areDutyOfficersOnly: onlyDutyOfficers,
        };
        this.localStore.setState({
            statuses: Object.values(OfficerStatus) as OfficerStatus[],
        });
        this.updateListOnQueryParamsChange();
    }

    protected changePage(event: PageEvent): void {
        this.updateQueryParams({ [PAGE_NUMBER_QUERY_PARAM]: event.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event.pageSize });
    }

    protected sortList(sort: Sort, resetPage = false): void {
        const active = sort.direction ? sort.active : null;
        const direction = sort.direction ? sort.direction : null;

        this.updateQueryParams({ active, direction, ...(resetPage && { [PAGE_NUMBER_QUERY_PARAM]: 0 }) });
    }

    protected applyFilters(filters: Partial<OfficerListFilters>): void {
        const { lastName, statuses, officerUnits, areDutyOfficersOnly } = filters ?? {};

        const params = {
            lastName: lastName ? lastName : null,
            statuses: statuses?.length ? statuses.join(",") : null,
            officerUnits: officerUnits?.length ? officerUnits.join(",") : null,
            areDutyOfficersOnly: areDutyOfficersOnly ? areDutyOfficersOnly : null,
            [PAGE_NUMBER_QUERY_PARAM]: 0,
        };

        this.updateQueryParams(params);
    }

    protected getOfficerList(): Promise<OfficerList> {
        const queryParams = this.route.snapshot.queryParams;

        return firstValueFrom(this.store.dispatch(new OfficerActions.GetOfficers(queryParams)).pipe(untilDestroyed(this)));
    }

    protected changeStatus(statusChange: OfficerStatusChange): void {
        this.store
            .dispatch(new OfficerActions.ChangeStatus(statusChange))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OfficerState.statusChangeError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateStatusErrorMessage"));

                    return;
                }

                this.getOfficerList().then(() =>
                    this.toastrService.success(
                        this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateStatusSuccessMessage")
                    )
                );
            });
    }

    protected promoteOfficer(officer: OfficerEntity) {
        this.store
            .dispatch(new OfficerActions.PromoteOfficer(officer.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OfficerState.promoteOfficerError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("uavIdAdminLibOfficer.officerList.promoteOfficerError"));

                    return;
                }

                this.getOfficerList().then(() =>
                    this.toastrService.success(
                        this.translocoService.translate("uavIdAdminLibOfficer.officerList.promoteOfficerSuccessMessage")
                    )
                );
            });
    }

    protected demoteOfficer(officer: OfficerEntity) {
        this.store
            .dispatch(new OfficerActions.DemoteOfficer(officer.id))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OfficerState.demoteOfficerError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("uavIdAdminLibOfficer.officerList.demoteOfficerError"));

                    return;
                }

                this.getOfficerList().then(() =>
                    this.toastrService.success(
                        this.translocoService.translate("uavIdAdminLibOfficer.officerList.demoteOfficerSuccessMessage")
                    )
                );
            });
    }

    protected updateOfficerUnit({ officerId, officerUnit }: OfficerUnitChange) {
        this.store
            .dispatch(new OfficerActions.UpdateOfficerUnit(officerId, officerUnit))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OfficerState.updateOfficerUnitError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateOfficerUnitError"));

                    return;
                }

                this.getOfficerList().then(() =>
                    this.toastrService.success(
                        this.translocoService.translate("uavIdAdminLibOfficer.officerList.updateOfficerUnitSuccessMessage")
                    )
                );
            });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router
            .navigate(["."], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: "merge",
            })
            .catch(Logger.captureException);
    }

    private updateListOnQueryParamsChange(): void {
        this.route.queryParams
            .pipe(
                distinctUntilChanged(),
                switchMap(() => this.getOfficerList()),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
