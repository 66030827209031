import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Data, Router, RoutesRecognized } from "@angular/router";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest, of, Subject } from "rxjs";
import { distinctUntilChanged, map, switchMap } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "uav-id-admin-header",
    templateUrl: "./header.component.html",
    styles: ["dtm-ui-header-user-button { margin-right: -12px; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    private readonly routeDataSubject = new Subject<Data | undefined>();
    public readonly routeTitle$ = this.routeDataSubject.pipe(
        switchMap((data) => {
            const titleTranslationKey = data?.titleTranslationKey;

            if (!titleTranslationKey) {
                return of("");
            }

            return this.translocoHelper.selectTranslation(titleTranslationKey, "");
        })
    );

    public readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    public readonly userName$ = combineLatest([this.store.select(AuthState.userFirstName), this.store.select(AuthState.userLastName)]).pipe(
        map(([firstName, lastName]) => `${firstName} ${lastName}`)
    );
    public readonly componentPortalItem$ = this.routeDataSubject.pipe(
        map((data) => data?.headerComponent),
        distinctUntilChanged(),
        map((headerComponent) => {
            if (!headerComponent) {
                return;
            }

            return new ComponentPortal(headerComponent);
        })
    );
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(
        private readonly router: Router,
        private readonly translocoHelper: TranslationHelperService,
        private readonly store: Store,
        private readonly deviceSizeService: DeviceSizeService
    ) {}

    public ngOnInit() {
        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                this.routeDataSubject.next(event.state.root.firstChild?.data);
            }
        });
    }

    public logout() {
        this.store.dispatch(new AuthActions.Logout());
    }
}
