import { SortDirection } from "@angular/material/sort";
import { Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { OfficerStatus, OfficerUnitEntity, StatusChange, UserType } from "@dtm-frontend/uav-identification-shared-lib/shared";

export interface OfficerListItem {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    officerUnit: OfficerUnitEntity;
    type: UserType;
    status: OfficerStatus;
}

export interface OfficerList {
    content: OfficerListItem[];
    page: Page;
}

export interface OfficerListFilters {
    lastName: string | null;
    statuses: OfficerStatus[] | null;
    officerUnits: string[] | null;
    areDutyOfficersOnly: boolean | null;
}

export interface OfficerListQueryParams {
    areDutyOfficersOnly: string;
    lastName: string;
    statuses: string;
    officerUnits: string;
    page: string;
    size: string;
    active: "lastName" | "officerUnit";
    direction: SortDirection;
}

export interface OfficerError {
    type: OfficerErrorType;
}

export enum OfficerErrorType {
    Unknown = "Unknown",
}

export interface OfficerStatusChange extends StatusChange {
    id: string;
    officerUnit?: OfficerUnitEntity;
}

export interface OfficerUnitChange {
    officerId: string;
    officerUnit: OfficerUnitEntity;
}
