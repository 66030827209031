<ng-container *ngrxLet="displayedTableColumns$ as displayedTableColumns">
    <ng-container *ngrxLet="reports$ as reports">
        <ng-container *ngrxLet="hasGetListErrorOccurred$ as hasGetListErrorOccurred">
            <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
                <dtm-ui-responsive-table *ngrxLet="reportsPage$ as page" [data]="reports">
                    <table
                        desktopTable
                        mat-table
                        matSort
                        class="dtm-table"
                        [dataSource]="reports ?? []"
                        (matSortChange)="sortChange.emit($event)"
                    >
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "uavIdAdminLibReportMgmt.reportList.statusLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <uav-id-shared-lib-status-badge-menu
                                    [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
                                    [status]="element.status"
                                    (statusChange)="updateStatus($event, element)"
                                ></uav-id-shared-lib-status-badge-menu>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="assignedOfficer">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "uavIdAdminLibReportMgmt.reportList.assignedOfficerLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.assignedOfficer | invoke : displayOfficerFullName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="number">
                                {{ "uavIdAdminLibReportMgmt.reportList.numberLabel" | transloco }}
                                <dtm-ui-popover
                                    class="number-info-icon"
                                    [popoverText]="'uavIdAdminLibReportMgmt.reportList.numberHeaderTooltipMessage' | transloco"
                                    (click)="$event.stopPropagation()"
                                ></dtm-ui-popover>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.number ?? "-" }}
                                <ng-container *ngTemplateOutlet="recordIconsTemplate; context: { $implicit: element }"></ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="reportedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="reportedAt">
                                {{ "uavIdAdminLibReportMgmt.reportList.reportedAtLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.reportedAt | localizeDate : { dateStyle: "short", timeStyle: "short" } }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="phoneNumber">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "uavIdAdminLibReportMgmt.reportList.phoneNumberLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">{{ (element.phoneNumber | formatPhoneNumber) ?? "-" }}</td>
                        </ng-container>

                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ "uavIdAdminLibReportMgmt.reportList.locationLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.location ?? "-" }}</td>
                        </ng-container>

                        <ng-container matColumnDef="officerUnit">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="officerUnit">
                                {{ "uavIdAdminLibReportMgmt.reportList.officerUnitLabel" | transloco }}
                            </th>
                            <td mat-cell *matCellDef="let element">{{ element.officerUnit.name ?? "-" }}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>{{ "uavIdAdminLibReportMgmt.reportList.actionsLabel" | transloco }}</th>
                            <td mat-cell *matCellDef="let element">
                                <button
                                    type="button"
                                    class="button-icon"
                                    [matMenuTriggerFor]="rowActionsMenu"
                                    [matMenuTriggerData]="{ $implicit: element }"
                                >
                                    <dtm-ui-icon name="more"></dtm-ui-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="noResults">
                            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                                <ng-container
                                    *ngIf="hasGetListErrorOccurred$ | ngrxPush; else emptyListTemplate"
                                    [ngTemplateOutlet]="getListErrorTemplate"
                                ></ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="pagination">
                            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                                <dtm-ui-pagination *ngIf="page" [pagination]="page" (page)="pageChange.emit($event)"></dtm-ui-pagination>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedTableColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedTableColumns"></tr>
                        <tr
                            mat-footer-row
                            *matFooterRowDef="['noResults']"
                            class="no-results-row"
                            [class.hide-footer]="reports.length || isProcessing"
                        ></tr>
                        <tr
                            mat-footer-row
                            *matFooterRowDef="['pagination']"
                            class="pagination-row"
                            [class.hide-footer]="!reports.length"
                        ></tr>
                    </table>

                    <div *dtmUiMobileTable="let row">
                        <div class="mobile-table-header">
                            <h2>{{ "uavIdAdminLibReportMgmt.reportList.reportHeaderLabel" | transloco : { number: row.number } }}</h2>
                            <ng-container *ngTemplateOutlet="recordIconsTemplate; context: { $implicit: row }"></ng-container>
                            <button
                                type="button"
                                class="mobile-table-header-actions button-icon"
                                [matMenuTriggerFor]="rowActionsMenu"
                                [matMenuTriggerData]="{ $implicit: row }"
                            >
                                <dtm-ui-icon name="more"></dtm-ui-icon>
                            </button>
                        </div>

                        <div class="mobile-table-content">
                            <uav-id-shared-lib-status-badge-menu
                                [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
                                [status]="row.status"
                                (statusChange)="updateStatus($event, row)"
                            ></uav-id-shared-lib-status-badge-menu>

                            <dtm-ui-label-value
                                [label]="'uavIdAdminLibReportMgmt.reportList.assignedOfficerLabel' | transloco"
                                [value]="row.assignedOfficer | invoke : displayOfficerFullName"
                            ></dtm-ui-label-value>
                            <dtm-ui-label-value
                                [label]="'uavIdAdminLibReportMgmt.reportList.reportedAtLabel' | transloco"
                                [value]="row.reportedAt | localizeDate : { dateStyle: 'short', timeStyle: 'short' }"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                [label]="'uavIdAdminLibReportMgmt.reportList.phoneNumberLabel' | transloco"
                                [value]="(row.phoneNumber | formatPhoneNumber) ?? '-'"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                [label]="'uavIdAdminLibReportMgmt.reportList.locationLabel' | transloco"
                                [value]="row.location ?? '-'"
                            >
                            </dtm-ui-label-value>
                            <dtm-ui-label-value
                                *ngIf="hasExtendedAccess$ | ngrxPush"
                                [label]="'uavIdAdminLibReportMgmt.reportList.officerUnitLabel' | transloco"
                                [value]="row.officerUnit.name ?? '-'"
                            >
                            </dtm-ui-label-value>
                        </div>
                    </div>

                    <dtm-ui-pagination
                        mobileTablePagination
                        *ngIf="page"
                        [pagination]="page"
                        (page)="pageChange.emit($event)"
                    ></dtm-ui-pagination>

                    <ng-container
                        *ngIf="hasGetListErrorOccurred$ | ngrxPush; else emptyListTemplate"
                        mobileTableNoData
                        [ngTemplateOutlet]="getListErrorTemplate"
                    ></ng-container>
                </dtm-ui-responsive-table>
            </dtm-ui-loader-container>
        </ng-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <button mat-menu-item type="button" [routerLink]="['.', element.id]">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "uavIdAdminLibReportMgmt.reportList.detailsActionLabel" | transloco }}
        </button>
        <button mat-menu-item type="button" (click)="interventionDialogOpen.emit(element)">
            <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
            {{
                (element.interventionNote
                    ? "uavIdAdminLibReportMgmt.reportList.editNoteActionLabel"
                    : "uavIdAdminLibReportMgmt.reportList.addNoteActionLabel"
                ) | transloco
            }}
        </button>
        <button mat-menu-item type="button" (click)="officerAssign.emit(element)">
            <dtm-ui-icon name="user-add"></dtm-ui-icon>
            {{
                (element.assignedOfficer
                    ? "uavIdAdminLibReportMgmt.reportList.assignOfficerEditActionLabel"
                    : "uavIdAdminLibReportMgmt.reportList.assignOfficerActionLabel"
                ) | transloco
            }}
        </button>
        <button mat-menu-item type="button" (click)="officerUnitChange.emit(element)">
            <dtm-ui-icon name="ball-pen"></dtm-ui-icon>
            {{ "uavIdAdminLibReportMgmt.reportList.changeOfficerUnitActionLabel" | transloco }}
        </button>
    </ng-template>
</mat-menu>

<ng-template #getListErrorTemplate>
    <dtm-ui-error
        [errorMessage]="'uavIdAdminLibReportMgmt.reportList.getListErrorMessage' | transloco"
        (reload)="pageReload.emit()"
    ></dtm-ui-error>
</ng-template>
<ng-template #emptyListTemplate>
    <dtm-ui-no-results></dtm-ui-no-results>
</ng-template>

<ng-template #recordIconsTemplate let-record>
    <dtm-ui-popover
        *ngIf="record.isCreatedByOfficer"
        class="officer-icon"
        [popoverText]="'uavIdAdminLibReportMgmt.report.createdByOfficerTooltipMessage' | transloco"
        [popoverIcon]="'shield-star'"
    ></dtm-ui-popover>
    <dtm-ui-popover
        *ngIf="record.isEmergencyReport"
        class="emergency-icon"
        [popoverText]="'uavIdAdminLibReportMgmt.report.emergencyReportTooltipMessage' | transloco"
        [popoverIcon]="'error-warning'"
    ></dtm-ui-popover>
</ng-template>
