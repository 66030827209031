<ng-container *ngrxLet="currentSlide$ as currentSlide">
    <div class="slider">
        <ol [style.left.%]="currentSlide * -100" class="slides">
            <li *ngFor="let slide of slides; let slideIndex = index" class="slide" [class.show]="currentSlide === slideIndex">
                <ng-container [ngTemplateOutlet]="slide.template"></ng-container>
            </li>
        </ol>
    </div>
    <div class="bottom-bar" *ngIf="slides.length">
        <p>{{ "dtmUi.carousel.slideOfSlidesLabel" | transloco : { currentSlideNumber: currentSlide + 1, slidesLength: slides.length } }}</p>
        <div class="actions">
            <button
                type="button"
                (click)="previousSlide()"
                class="button-icon"
                [attr.aria-label]="'dtmUi.carousel.previousSlideAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-left"></dtm-ui-icon>
            </button>
            <button
                type="button"
                (click)="nextSlide()"
                class="button-icon"
                [attr.aria-label]="'dtmUi.carousel.nextSlideAriaLabel' | transloco"
            >
                <dtm-ui-icon name="arrow-right"></dtm-ui-icon>
            </button>
        </div>
    </div>
</ng-container>
